import { data } from "../../../globals/data/about-us";
import { NavLink } from "react-router-dom";
import { SectionFaq } from "../../sections/home/index3/section-faq";
import { data as faqData } from "../../../globals/data/faq";
import { SectionAddress } from "../../sections/home/index3/section-address";
import SectionTestimonials2 from "../../sections/home/index2/section-testimonials2";

export default function AboutUsPage() {
  const _data = data.whatwedo;
  const dataBanner = data.banner;
  return (
    <>
      <div
        className="wt-bnr-inr overlay-wraper bg-center"
        style={{ backgroundImage: `url(${dataBanner.background})` }}
      >
        <div className="overlay-main opacity-08" />
        <div className="container">
          <div className="wt-bnr-inr-entry">
            <div className="banner-title-outer">
              <div className="banner-title-name">
                <h2 className="wt-title">{dataBanner.title}</h2>
              </div>
            </div>
            {/* BREADCRUMB ROW */}
            <div>
              <ul className="wt-breadcrumb breadcrumb-style-2">
                <li>
                  <NavLink to="/">Asosiy oyna</NavLink>
                </li>
                <li>{dataBanner.crumb}</li>
              </ul>
            </div>
            {/* BREADCRUMB ROW END */}
          </div>
        </div>
      </div>
      <div className="section-full p-t120 p-b90 site-bg-gray tw-what-wedo-area">
        <div className="container">
          {/* <!-- TITLE START--> */}
          <div className="section-head center wt-small-separator-outer">
            <h2 className="wt-title">Biz haqimizda</h2>
            <p className="section-head-text">
              "DOOR TO DOOR"da har bir mijozning talabi biz uchun ustuvor
              ahamiyatga ega. Biz bilan hamkorlik - bu xavfsizlik va ishonchning
              garantidir.
            </p>
          </div>
          {/* <!-- TITLE END--> */}
          <div className="tw-what-wedo-section">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12">
                <div className="tw-what-wedo-media">
                  <img src={_data.image} alt="Door to Door cargo - about us" />
                </div>
              </div>
              <div className="col-xl-7 col-lg-7 col-md-12">
                <div className="tw-service-icon-box-wrap">
                  <p className="section-head-text" style={{ fontSize: "18px" }}>
                    "DOOR TO DOOR" MCHJ – bu Xitoydan O'zbekistonga har qanday
                    hajmdagi yuklarni tezkor, xavfsiz va yuqori sifatli tarzda
                    yetkazib beruvchi xalqaro kompaniya. Mijozlarga sifatli
                    xizmat ko'rsatish bizning asosiy maqsadimiz. Kompaniyamiz
                    o'z faoliyatini boshlaganidan beri, 20,000 dan ortiq
                    buyurtmalarni muvaffaqiyatli yetkazib berdi. Har bir
                    buyurtma, bizning tajribali jamoamizning e'tiborli nazorati
                    ostida, individual yondashuv asosida amalga oshiriladi. Shu
                    tariqa, mijozlarimizning talab va istaklarini aniq va o'z
                    vaqtida bajarishni ta'minlaymiz. Hozirgi kunda ro'yxatdan
                    o'tgan mijozlarimiz soni 6,000 dan oshdi, bu esa bizning
                    ishimizga, javobgarligimizga va mas'uliyatimizga bo'lgan
                    ishonchni aks ettiradi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SectionTestimonials2 _data={data.testimonials} />

      <SectionFaq faqData={faqData} />

      <SectionAddress />
    </>
  );
}
