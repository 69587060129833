import RootLayout from "./layouts/root-layout";
import ScrollToTop from "./app/elements/ScrollToTop";

export default function App() {
  return (
    <>
      <ScrollToTop />
      <RootLayout />
    </>
  );
}
