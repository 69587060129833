import React, { useEffect, useRef, useState, useCallback } from "react";
import SectionSlider3 from "../../sections/home/index3/section-slider3";
import SectionWhyChooseUs2 from "../../sections/home/index2/section-why-choose-us2";
import SectionTestimonials2 from "../../sections/home/index2/section-testimonials2";
import SectionSliderLogo from "../../sections/home/index3/section-slider-logo";
import { SectionFaq } from "../../sections/home/index3/section-faq";
import { SectionAddress } from "../../sections/home/index3/section-address";
import { data } from "../../../globals/data/home2";
import { handleFormSubmission, loadCustomScript } from "../misc/helper";
import { data as faqData } from "../../../globals/data/faq";
import { newsBgImagePath } from "../../../globals/constants";
import InputMask from "react-input-mask";

import { Modal } from "react-bootstrap";

function Home3Page() {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
  });
  const [formErrors, setFormErrors] = useState({
    name: "",
    phone: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadCustomScript();
  }, []);

  const mapRef = useRef(null);

  useEffect(() => {
    const mapInstance = mapRef.current;
    if (mapInstance) {
      mapInstance.behaviors.disable("scrollZoom"); // Disable default scroll zoom

      mapInstance.events.add("wheel", (e) => {
        if (e.get("domEvent").originalEvent.ctrlKey) {
          e.preventDefault();
          const delta = e.get("delta");
          const zoom = mapInstance.getZoom();
          if (delta > 0) {
            mapInstance.setZoom(zoom + 1, { checkZoomRange: true });
          } else {
            mapInstance.setZoom(zoom - 1, { checkZoomRange: true });
          }
        }
      });
    }
  }, []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const errors = {};
    if (
      !formData.name.trim() ||
      formData.name?.length > 50 ||
      formData.name?.indexOf(".zip") > -1 ||
      formData.name?.indexOf("file") > -1 ||
      formData.name?.indexOf("path") > -1
    ) {
      errors.name = "Ismingizni to'ldiring";
    }
    const phoneWithoutMask = formData.phone.replace(/\D/g, "");
    if (
      phoneWithoutMask.length < 12 ||
      phoneWithoutMask.indexOf("99802") > -1
    ) {
      errors.phone = "Telefon raqamingizni to'ldiring (minimum 12 raqam)";
    }
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const formSubmitted = await handleFormSubmission(formData);
        if (formSubmitted) {
          setFormData({
            name: "",
            phone: "",
          });
          setShowSuccessModal(true);
        }
      } catch (err) {
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const handleClose = () => setShowSuccessModal(false);

  return (
    <>
      <SectionSlider3 />
      <SectionWhyChooseUs2 _data={data.whychoosus} />
      <SectionTestimonials2 _data={data.testimonials} />
      <SectionSliderLogo _data={data.logoslider} />
      <SectionFaq faqData={faqData} />
      {/* NEWS LETTER SECTION START */}
      <div id="contact-us" className="ftr-nw-ltr site-bg-white">
        <div className="ftr-nw-ltr-inner site-bg-primary">
          <div className="container">
            <div className="ftr-nw-img">
              <img src={newsBgImagePath} alt="Ishonchli Cargo Door to Door" />
            </div>
            <div className="ftr-nw-content">
              <div className="ftr-nw-title">Hozir Ro'yxatdan O'ting</div>
              <div className="ftr-nw-form">
                <form onSubmit={handleSubmit}>
                  <input
                    name="name"
                    className="form-control"
                    placeholder="Ismingiz"
                    type="text"
                    value={formData.name}
                    onChange={handleInputChange}
                    // required
                  />
                  <div className="mb-2">
                    <span className="text-danger">{formErrors.name}</span>
                  </div>
                  <InputMask
                    mask="+\9\98 (99) 999-99-99"
                    name="phone"
                    maskChar="_"
                    className="form-control"
                    placeholder="Telefon raqamingiz"
                    value={formData.phone}
                    onChange={handleInputChange}
                    // required
                  />
                  <div className="mb-2">
                    <span className="text-danger">{formErrors.phone}</span>
                  </div>
                  <div className="shine-effect">
                    <button
                      className="ftr-nw-subcribe-btn shine-button"
                      type="submit"
                      disabled={loading}
                    >
                      Ro'yxatdan o'tish
                    </button>
                  </div>
                </form>
                <Modal show={showSuccessModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      Rahmat, ma'lumotingizni qabul qildik!
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Biz sizning murojaatingizni qabul qildik va tez orada siz
                    bilan bog'lanamiz. Siz bizning qimmatli mijozimizsiz va
                    sizning ehtiyojlaringiz biz uchun eng muhimdir. Sizni{" "}
                    <b>Door to Door</b> oilasiga qo'shilganingizdan mamnunmiz!
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* NEWS LETTER SECTION END */}
      <SectionAddress />
    </>
  );
}

export default Home3Page;
