export const SectionFaq = ({ faqData = {} }) => {
  return (
    <div id="faq" className="section-full p-t30 p-b90 tw-faq-area">
      <div className="container">
        {/* TITLE START*/}
        <div className="section-head center wt-small-separator-outer">
          <div className="wt-small-separator site-text-primary">
            <div>{faqData.faq.title}</div>
          </div>
          <h2 className="wt-title">{faqData.faq.subtitle}</h2>
          <p className="section-head-text">{faqData.faq.description}</p>
        </div>
        {/* TITLE END*/}
        <div className="section-content">
          <div className="tw-faq-section">
            <div className="accordion tw-faq" id="sf-faq-accordion">
              {faqData.faq.faqs.map((_object, index) => (
                <div key={index} className="accordion-item">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#FAQ" + index}
                    aria-expanded="false"
                  >
                    {_object.que}
                  </button>
                  <div
                    id={"FAQ" + index}
                    className="accordion-collapse collapse"
                    data-bs-parent="#sf-faq-accordion"
                  >
                    <div
                      className="accordion-body"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {_object.ans}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
