import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";
import { useEffect, useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);

  function toggleNavClass() {
    setIsActive(!isActive);
  }

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href"))?.scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  return (
    <header
      className={
        "site-header header-style-3 mobile-sider-drawer-menu " +
        (isActive ? "active" : "")
      }
    >
      <div className="sticky-header main-bar-wraper  navbar-expand-lg">
        <div className="main-bar">
          <div className="container-fluid clearfix">
            <div className="logo-header">
              <div className="logo-header-inner logo-header-one">
                <NavLink to="/">
                  <img
                    src={publicUrlFor("/assets/images/logo-dark.png")}
                    alt="#"
                  />
                </NavLink>
              </div>
            </div>

            {/* NAV Toggle Button */}
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              className="navbar-toggler collapsed"
              onClick={toggleNavClass}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first"></span>
              <span className="icon-bar icon-bar-two"></span>
              <span className="icon-bar icon-bar-three"></span>
            </button>

            {/* MAIN Vav */}
            <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
              <ul className=" nav navbar-nav">
                <li>
                  <a href="#why-choose-us">
                    Xitoydan Yetkazib Berish Xizmatlari
                  </a>
                </li>
                <li>
                  <NavLink to="/about-us">Biz haqimizda</NavLink>
                </li>
                <ul className=" nav navbar-nav none">
                  <li>
                    <a href="#contact-us">Bog'lanish</a>
                  </li>
                  <li>
                    <a href="#faq">Ko'p so'raladigan savollar</a>
                  </li>
                  <li>
                    <a
                      href="https://trek.d2d.uz?utm_source=landing&utm_medium=navbar"
                      target="blank"
                    >
                      Trek raqam
                    </a>
                  </li>
                </ul>
                <li className="has-child block">
                  <a href="#c">Va Boshqalar</a>
                  <div className="fa fa-angle-right submenu-toogle"></div>
                  <ul className="sub-menu">
                    <li>
                      <a href="#contact-us">Bog'lanish</a>
                    </li>
                    <li>
                      <a href="#faq">Ko'p so'raladigan savollar</a>
                    </li>
                    <li>
                      <a
                        href="https://trek.d2d.uz?utm_source=landing&utm_medium=navbar"
                        target="blank"
                      >
                        Trek raqam
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            {/* Header Right Section */}
            <div className="extra-nav header-2-nav">
              <div className="extra-cell">
                <div className="header-nav-call-section">
                  <div className="detail">
                    <span className="title">Savollar Uchun Call</span>
                    <span>
                      <a href="tel:+998993745030">+998 99 374 50 30</a>
                    </span>
                  </div>
                  <div className="media">
                    <a href="tel:+998993745030">
                      <img
                        src={publicUrlFor("/assets/images/call.png")}
                        alt="#"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* SITE Search */}
        <div id="search">
          <span className="close"></span>
          <form
            role="search"
            id="searchform"
            action="/search"
            method="get"
            className="radius-xl"
          >
            <input
              className="form-control"
              name="q"
              type="search"
              placeholder="Type to search"
            />
            <span className="input-group-append">
              <button type="button" className="search-btn">
                <i className="fa fa-paper-plane"></i>
              </button>
            </span>
          </form>
        </div>
      </div>
    </header>
  );
}

export default Header;
