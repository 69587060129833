import { Route, Routes } from "react-router-dom";
import Home3Page from "../app/components/home/index3";
import AboutUsPage from "../app/components/misc/about-us";
import Error404Page from "../app/components/misc/error404";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Home3Page />} />
      <Route path="/about-us" element={<AboutUsPage />} />
      <Route path="*" element={<Error404Page />} />
      <Route element={<Error404Page />} />
    </Routes>
  );
}

export default AppRoutes;
