import {
  API_ENDPOINT,
  customScriptPath,
  loadScript,
} from "../../../globals/constants";

export const loadCustomScript = async () => {
  await loadScript(customScriptPath, true);
};

export const handleFormSubmission = async (formData) => {
  try {
    const response = await fetch(API_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      console.log("Form submitted successfully", formData);
      return true;
    } else {
      console.error("Failed to submit the form");
    }
  } catch (error) {
    console.error("Error during form submission:", error);
    if (error.response) {
      console.error("Response error:", await error.response.text());
    }
    return false;
  }
};
