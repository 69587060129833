import { useEffect } from "react";
import { publicUrlFor, loadScript } from "../../../../globals/constants";

export default function SectionSlider3() {
  useEffect(() => {
    loadScript("/assets/js/slider-home3.js", true);
    loadScript("/assets/js/bg-image-move.js", true);
  });

  return (
    <>
      <div className="twm-slider3-wrap">
        {/* Swiper */}
        <div className="swiper twm-slider3">
          <div className="swiper-wrapper">
            {/*Slide Three Plane*/}
            <div
              className="swiper-slide bg-cover bg-image-moving2"
              style={{
                backgroundImage: `url(${publicUrlFor(
                  "/assets/images/main-slider/slider3/bg-large3.webp"
                )})`,
              }}
            >
              <div className="h-banner-wrap">
                <div className="h-banner-left">
                  <div className="h-bnr-top shine-effect">
                    <span
                      className="title-small"
                      style={{ textShadow: "1px 1px 10px black" }}
                    >
                      Door to Door
                    </span>
                    <h2 style={{ textShadow: "1px 1px 10px black" }}>
                      Xitoydan O'zbekistonga Tezkor Yetkazib Berish Xizmati
                    </h2>
                    <a
                      href="#contact-us"
                      className="h3-bnr-btn site-button shine-button"
                    >
                      Bog'lanish
                    </a>
                  </div>
                </div>
                <div className="h-banner-right">
                  <div className="h-bnr-r-inner">
                    <div className="h-bnr-media slider-scale">
                      <img
                        src={publicUrlFor(
                          "/assets/images/main-slider/slider3/airplan1.webp"
                        )}
                        alt="Xitoydan yetkazib berish xizmati Door to Door"
                        className="slide-top-fast"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ani-clowd1 clowd1-move">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider3/clowd-1.webp"
                  )}
                  alt="Xitoydan yetkazib berish xizmati Door to Door"
                />
              </div>
              <div className="ani-clowd2 clowd2-move">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider3/clowd-2.webp"
                  )}
                  alt="Xitoydan yetkazib berish xizmati Door to Door"
                />
              </div>
              <div className="ani-clowd3 clowd3-move">
                <img
                  src={publicUrlFor(
                    "/assets/images/main-slider/slider3/clowd-3.webp"
                  )}
                  alt="Xitoydan yetkazib berish xizmati Door to Door"
                />
              </div>
            </div>
            {/*Slide Two Truck*/}
            <div
              className="swiper-slide bg-cover bg-image-moving2"
              style={{
                backgroundImage: `url(${publicUrlFor(
                  "/assets/images/main-slider/slider3/bg-large-6.webp"
                )})`,
              }}
            >
              <div className="h-banner-wrap">
                <div className="h-banner-left">
                  <div className="h-bnr-top shine-effect">
                    <span
                      style={{ textShadow: "1px 1px 10px black" }}
                      className="title-small"
                    >
                      Door to Door
                    </span>
                    <h2 style={{ textShadow: "1px 1px 10px black" }}>
                      Ishonchli, Tezkor va albatta Hamyonbop
                    </h2>
                    <a
                      href="#contact-us"
                      className="h3-bnr-btn site-button shine-button"
                    >
                      Bog'lanish
                    </a>
                  </div>
                </div>
                <div className="h-banner-right">
                  <div className="h-bnr-r-inner">
                    <div className="h-bnr-media slider-scale2">
                      <div className="trck-animation-wrap slide-top-slow">
                        <img
                          src={publicUrlFor(
                            "/assets/images/main-slider/slider3/truck2.webp"
                          )}
                          alt="Ishonchli Kargo Door to Door"
                        />
                        <span className="tyre-1">
                          <img
                            src={publicUrlFor(
                              "/assets/images/main-slider/slider3/balon2.webp"
                            )}
                            alt="Xitoydan yetkazib berish xizmati Door to Door"
                            className="spin-tyres"
                          />
                        </span>
                        <span className="tyre-3">
                          <img
                            src={publicUrlFor(
                              "/assets/images/main-slider/slider3/balon2.webp"
                            )}
                            alt="Xitoydan yetkazib berish xizmati Door to Door"
                            className="spin-tyres"
                          />
                        </span>
                        <span className="truck-light">
                          <img
                            src={publicUrlFor(
                              "/assets/images/main-slider/slider3/truck-light.png"
                            )}
                            alt="Xitoydan yetkazib berish xizmati Door to Door"
                            className="blink-image"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* navigation Numbers */}
          <div className="swiper-pagination" />
          {/* navigation Arrow */}
          <div className="swiper-button-prev" />
          <div className="swiper-button-next" />
        </div>
      </div>
    </>
  );
}
