import { global } from "../constants";

export const data = {
  banner: {
    background: global.banner + "1.jpg",
    title: "FAQ",
    crumb: "FAQ",
  },
  faq: {
    title: "FAQ's",
    subtitle: "Ko'p So'raladigan Savollar?",
    description:
      "Agar sizda boshqa savollar mavjud bo'lsa yoki savolingizga javob olmagan bo'lsangiz bizga raqamingizni qoldiring va biz sizga aloqaga chiqib barcha savollaringizga javob beramiz!",
    faqs: [
      {
        que: "Cargo Narxi Qancha?",
        ans: `✈️ DTD AVIA CARGO Tarifi:
- 150 gr gacha: 1.5 $
- 150 gr dan 10 kg gacha: 10 $ (har bir kg)
- 10.1 kg dan 40 kg gacha: 9.5 $ (har bir kg)
- 40.1 kg dan ko'pi: 9 $ (har bir kg)

Kelish muddati: 7 - 14 kun
-------------------------------------
🚛 DTD AVTO CARGO Tarifi:
- 10 kg gacha: 8 $
- 10 kg dan 30 kg gacha: 7.5 $
- 30 kg dan 70 kg gacha: 7 $
- 70 kg dan tepasi: 6.7 $

Kelish muddati: 20 - 25 kun
-----------------------------------
📦 Elektronika, Brend, Parfumeriya va Lyuks Kopiya Maxsulotlar Tarifi:
- Airpots: 5 $ (donasi)
- Soatlar: 5 $ (donasi)
- Parfyumeriya (krem, tush, shampun, pomada va hokazo): 10 $ (kg)
- Elektronikalar: 10 $ (kg)
- Brend kiyimlar: 10 $ (kg)

Kelish muddati: 25 - 30 kun
-----------------------------------
📌 Bundan tashqari, individual xizmat va narxlarimiz bor. Qo'shimcha ma'lumot uchun biz bilan bog'laning!
`,
      },
      {
        que: "Nechchi Kunda Keladi?",
        ans: `✈️ DTD AVIA CARGO:

Kelish muddati: 7 - 14 kun
-------------------------------------
🚛 DTD AVTO CARGO:

Kelish muddati: 14 - 20 kun
-----------------------------------
📦 Elektronika, Brend, Parfumeriya va Lyuks Kopiya Maxsulotlar:

Kelish muddati: 25 - 30 kun`,
      },
      {
        que: "Narsalar Yo'qolmaydimi?",
        ans: "Bizning zamonaviy kuzatuv tizimlarimiz va ehtiyotkorlik choralari bilan sizning yuklaringiz xavfsiz va o'z vaqtida yetkazilishini ta'minlaymiz. Har bir yuk biz uchun qimmatli va biz uni sizgacha yetkazishda maksimal darajada ehtiyot bo'lamiz.",
      },
      {
        que: "Mahsulotimni Kuzatib Borish Imkoni Bormi?",
        ans: "Ha, bizning raqamli kuzatuv xizmatimiz orqali sizning mahsulotingizni real vaqtda kuzatib borishingiz mumkin. Shunchaki bizning veb-saytimizdagi 'Trek raqam' bo'limiga o'ting va yukingizning holatini tekshiring.",
      },
      {
        que: "Qanday Mahsulotlarni Olib Kelish Mumkin emas?",
        ans: `❌ Olib kelish mumkin bo'lmagan mahsulotlar:

-Telefon
- Kompyuter
- Planshet va iPad
- Televizor
- O'simliklar
- Dorilar
- Dron
- Sovuq qurollar
- Intim mahsulotlar
- Yonuvchan moddalar (atirlar)
- Batareykasi asosiy qismini egallaydigan mahsulotlar (Powerbank)
- Tirik hayvonlar`,
      },
      {
        que: "DUR nima?",
        ans: `💎 DUR – bu Door to Door kompaniyasining keshbek tizimi. Har bir kilogramm yuk uchun 1 DUR ball olasiz. 1 DUR hozirda 50 gramm yuk narxiga teng. DURlarni to'plab, keyingi jo'natmalaringiz uchun ishlatishingiz mumkin.

👥 Do'stingizni taklif qiling: Do'stingizni taklif qilsangiz, qo'shimcha DUR ballariga ega bo'lasiz.

🎉 Aksiyalar va bayramlar: Aksiyalar va bayramlarda qo'shimcha DUR ballarini qo'lga kiriting.

🎁 Qo'shiling va 5 DUR oling!`,
      },
    ],
  },
};
