import { useEffect, useRef } from "react";
import {
  FullscreenControl,
  Map,
  Placemark,
  YMaps,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import { publicUrlFor } from "../../../../globals/constants";

export const SectionAddress = () => {
  const mapState = {
    center: [41.213999, 69.213331],
    zoom: 17,
  };

  const mapOptions = {
    // Enabling zooming only with Ctrl key
    suppressMapOpenBlock: true,
    yandexMapDisablePoiInteractivity: true,
  };

  const placemarkGeometry = [41.213844, 69.212408];
  const placemarkProperties = {
    hintContent: "Door to Door Cargo",
    balloonContent: "Door to Door Cargo",
  };

  const mapRef = useRef(null);

  useEffect(() => {
    const mapInstance = mapRef.current;
    if (mapInstance) {
      mapInstance.behaviors.disable("scrollZoom"); // Disable default scroll zoom

      mapInstance.events.add("wheel", (e) => {
        if (e.get("domEvent").originalEvent.ctrlKey) {
          e.preventDefault();
          const delta = e.get("delta");
          const zoom = mapInstance.getZoom();
          if (delta > 0) {
            mapInstance.setZoom(zoom + 1, { checkZoomRange: true });
          } else {
            mapInstance.setZoom(zoom - 1, { checkZoomRange: true });
          }
        }
      });
    }
  }, []);

  return (
    <div className="section-full p-b120 p-t120" id="address">
      <div className="section-content">
        <div className="container">
          <div className="contact-one">
            {/* TITLE START*/}
            <div className="section-head left wt-small-separator-outer">
              <h2 className="wt-title m-b30">Bizning Manzil</h2>
            </div>
            {/* TITLE END*/}
            {/* CONTACT FORM*/}
            <div className="contact-one-inner">
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div style={{ width: "100%", height: "460px" }}>
                    <YMaps
                      query={{ apikey: "c5709e4e-28c7-481a-be91-bdfb52b5cac0" }}
                    >
                      <Map
                        state={mapState}
                        defaultOptions={mapOptions}
                        options={mapOptions}
                        width="100%"
                        height="100%"
                        instanceRef={mapRef}
                      >
                        <FullscreenControl />
                        <ZoomControl
                          options={{ float: "right" }}
                          data={{ events: {} }}
                        />
                        <Placemark
                          geometry={placemarkGeometry}
                          properties={placemarkProperties}
                        />
                      </Map>
                    </YMaps>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div
                    className="contact-info  site-bg-dark bg-no-repeat bg-bottom-left"
                    style={{
                      backgroundImage: `url(${publicUrlFor(
                        "/assets/images/background/contact-bg.png"
                      )})`,
                    }}
                    itemScope
                    itemType="http://schema.org/LocalBusiness"
                  >
                    {/* TITLE START*/}
                    <div className="section-head-small white mb-4">
                      <h1 itemProp="name" className="d-none">
                        DTD Cargo
                      </h1>
                      <h3 className="wt-title">Biz bilan bog'lanish uchun</h3>
                    </div>
                    {/* TITLE END*/}
                    <div
                      className="contact-info-section"
                      itemProp="address"
                      itemScope
                      itemType="http://shcema.org/PostalAddress"
                    >
                      <div className="c-info-column">
                        <div className="c-info-icon fa fa-map-marker">
                          <span />
                        </div>
                        <p style={{ fontSize: "14px", fontWeight: "normal" }}>
                          <span
                            itemProp="streetAddress"
                            className="d-inline"
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            massiv O'zgarish, 61
                          </span>
                          ,{" "}
                          <span
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                            itemProp="addressRegion"
                            className="d-inline"
                          >
                            Yangihayot tumani
                          </span>
                          ,{" "}
                          <span
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                            itemProp="addressLocality"
                            className="d-inline"
                          >
                            Toshkent
                          </span>
                          .
                          <span
                            style={{ fontSize: "14px", fontWeight: "normal" }}
                          >
                            Mo'ljal: Yangihayot metro bekati (oldingi 4-metro
                            bekati)
                          </span>
                        </p>
                      </div>
                      <div className="c-info-column">
                        <div className="c-info-icon fa fa-mobile-phone custome-size">
                          <span />
                        </div>
                        <p>
                          <a href="tel:+998993745030" itemProp="telephone">
                            +998 99 374 50 30
                          </a>
                        </p>
                        <p>
                          <a href="tel:+998993735030" itemProp="telephone">
                            +998 99 373 50 30
                          </a>
                        </p>
                      </div>
                      <div className="c-info-column">
                        <div className="c-info-icon fa fa-envelope-o">
                          <span />
                        </div>
                        <p>
                          <a href="mailto:info@d2d.uz" itemProp="email">
                            info@d2d.uz
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
