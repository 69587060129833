import { useLocation } from "react-router-dom";

import Header from "../app/sections/common/header";
import Footer1 from "../app/sections/common/footer1";
import Footer2 from "../app/sections/common/footer2";
import AppRoutes from "../routing/app-routes";
import { useState } from "react";

export default function RootLayout() {
  const currentpath = useLocation().pathname;
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="page-wraper">
        {!currentpath?.startsWith("/error") && <Header />}

        {/* CONTENT START */}
        <div className="page-content">
          <AppRoutes />
        </div>
        {/* CONTENT END */}

        {!currentpath?.startsWith("/error") &&
          (((currentpath === "/index2" || currentpath === "/index4") && (
            <Footer2 />
          )) || <Footer1 />)}

        {/* BUTTON TOP START */}
        <button className="scroltop" onClick={scrollToTop}>
          <span
            className="fa fa-angle-up  relative"
            onClick={scrollToTop}
          ></span>
        </button>
        <div className={`menu-box ${isMenuOpen ? "menu-toggler" : ""}`}>
          <div className="menu">
            <ul>
              <li>
                <a
                  style={{ "--clr": "rgb(56, 56, 246)" }}
                  href="https://t.me/DTD_channel"
                  target="blank"
                  className="a1"
                >
                  <span className="icon fa fa-telegram"></span>
                  {/* <span className="text">Telegram</span> */}
                </a>
              </li>
              <li>
                <a
                  style={{ "--clr": "rgb(253, 51, 51)" }}
                  href="https://www.instagram.com/dtd_kargo_uz/"
                  target="blank"
                  className="a2"
                >
                  <span className="icon fa fa-instagram"></span>
                  {/* <span className="text">Instagram</span> */}
                </a>
              </li>
            </ul>
          </div>
          <button className="menu-viewer" onClick={handleMenuToggle}></button>
        </div>
      </div>
    </>
  );
}
