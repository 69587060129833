import { useEffect, useState } from "react";
import { publicUrlFor } from "../../../../globals/constants";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

var sliderThumb = null;

export default function SectionTestimonials2({ _data }) {
  const sliderContentSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    fade: false,
    vertical: false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [navThumb, setNavThumb] = useState(null);

  useEffect(() => {
    setNavThumb(sliderThumb);
  });

  return (
    <>
      <div
        className="section-full bg-cover p-t120 p-b120 bg-cover tw-testimonial-2-area"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "/assets/images/background/bg-6.jpg"
          )})`,
        }}
      >
        {/* TITLE START*/}
        <div className="section-head center wt-small-separator-outer">
          <div className="wt-small-separator site-text-primary">
            <div>{_data.title}</div>
          </div>
          <h2 className="wt-title">{_data.subtitle}</h2>
        </div>
        {/* TITLE END*/}
        <div className="tw-testimonial-2-area-inner">
          <div className="container">
            <div className="row">
              <div className="slider-testimonial-2-wrap">
                {/* MAIN SLIDES */}
                <Slider
                  {...sliderContentSettings}
                  asNavFor={navThumb}
                  ref={(slider) => slider}
                >
                  {_data.feedbacks.map((_object, index) => (
                    <div key={index} className="slick-item">
                      <div className="tw-testimonials2-section">
                        <img src={_object.image} alt="#" />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
