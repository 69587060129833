import { NavLink } from "react-router-dom";
import { publicUrlFor } from "../../../globals/constants";

export default function Footer1() {
  return (
    <>
      <footer className="footer-dark">
        <div
          className="ftr-bg"
          style={{
            backgroundImage: `url(${publicUrlFor("/assets/images/f-bg.jpg")})`,
          }}
        >
          {/* FOOTER BLOCKES START */}
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_about">
                    <div className="logo-footer clearfix">
                      <NavLink to="/index">
                        <img
                          src={publicUrlFor("/assets/images/logo-dark.png")}
                          alt="#"
                        />
                      </NavLink>
                    </div>
                    <h5 style={{ color: "#fff" }}>
                      DTD Cargo: Sizning Tezkor Yetkazib Berish Sherigingiz
                    </h5>
                    <p>
                      Biz Xitoy va O'zbekiston o'rtasidagi savdo ko'prigimiz.
                      Har kuni 24 soat davomida sizning yuklaringizni tezkor,
                      ishonchli va xavfsiz yetkazib beramiz.
                    </p>
                    <ul className="social-icons">
                      {/* <li>
                        <a
                          href="https://www.facebook.com/"
                          className="fa fa-facebook"
                        >
                          {" "}
                        </a>
                      </li> */}

                      <li>
                        <a
                          href="https://www.instagram.com/dtd_kargo_uz/"
                          className="fa fa-instagram"
                        >
                          {" "}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://t.me/DTD_channel"
                          className="fa fa-telegram"
                        >
                          {" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Tezkor sahifalar</h3>
                    <ul>
                      <li>
                        <a href="#why-choose-us">Nima uchun biz?</a>
                      </li>
                      <li>
                        <a href="#faq">Ko'p so'raladigan savollar</a>
                      </li>
                      <li>
                        <a href="#address">Biz bilan bo'g'lanish</a>
                      </li>
                      <li>
                        <a href="#contact-us">Ro'yhatdan o'tish</a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-3 col-md-6"></div>

                <div className="col-lg-3 col-md-6">
                  <div className="widget widget_services ftr-list-center">
                    <h3 className="widget-title">Community</h3>
                    <ul>
                      <li>
                        <a href="#address">
                          📍 Manzil: massiv O'zgarish, 61, Yangihayot tumani,
                          Toshkent.
                        </a>
                      </li>
                      <li>
                        <a href="#address">📞 Aloqa: +998 99 374 50 30</a>
                      </li>
                      <li>
                        <a href="#address">📞 Aloqa: +998 99 373 50 30</a>
                      </li>
                      <li>
                        <a href="#address">✉️ E-mail: info@d2d.uz</a>
                      </li>
                      <li>
                        <a href="#address">
                          ⏰ Ish vaqtlari: <br />
                          Dushanba - Shanba 9:00 - 18:00
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}

          <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-info">
                <div className="footer-copy-right">
                  <span className="copyrights-text">
                    {/* TODO year */}© {new Date().getFullYear()} by Door to
                    Door. All Rights Reserved.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
