import { global } from "../constants";

export const data = {
  whychoosus: {
    title: "NIMA UCHUN BIZNI TANLASH KERAK",
    subtitle: "Sizning Ishonchli Logistika va Cargo Hamkoringiz",
    description:
      "Biz sizning Xitoydan O'zbekistonga bo'lgan har qanday yuklaringizni tezkor, xavfsiz va samarali tarzda yetkazib beramiz. DTD cargo bilan sizning yuklaringiz ishonchli qo'llarda",
    branches: "7 - 14",
    since: "",
    specifications: [
      "Tezkor yetkazib berish: Yuklaringizni xavfsiz va tezkor - atiga 7 kundan 14 kungacha bo'lgan muddatda yetkazib beramiz. Sizning vaqtingiz biz uchun qimmatli.",
      "Tajribali mutaxassislar: Har bir yetkazib berish jarayoni, tajribali mutaxassislarimizning diqqat markazida. Sizning yukingiz biz uchun qadrli.",
      "Real vaqt rejimida kuzatish: Yuklaringizning harakatini real vaqt rejimida kuzatib borish imkoniyati sizga ishonch baxsh etadi.",
      "Yuqori xizmat ko'rsatish madaniyati: Mijozlarimizga xizmat ko'rsatishda yuqori madaniyat va hurmat – bizning asosiy tamoyilimiz. Siz uchun har doim shaymiz.",
      "Oson bog'lanish: Bir qo'ng'iroq yoki ijtimoiy tarmoq orqali tezkor va oson yordam olishingiz mumkin. Biz sizni hech qachon kuttirib qo'ymaymiz.",
      "Yuklaringiz to'liq himoyada: Buyurtmalaringizni asrab-avaylab, beshikast yetkazish bizning vazifamiz.",
      "DUR: Hozir bizga qo'shiling va 5 DUR olasiz, bu qiymatda xizmatlarimizdan bepul foydalinish imkoniyatiga ega bo'lasiz. Har bir buyurtma uchun DUR yani keshbek va do'stingizni taklif qilsangiz ham DUR olasiz.",
    ],
  },

  logoslider: {
    logoLink: [
      {
        id: 1,
        image: global.logo + "w1.png",
        link: "1688.com",
      },
      {
        id: 2,
        image: global.logo + "w2.png",
        link: "taobao.com",
      },
      {
        id: 3,
        image: global.logo + "w3.png",
        link: "pinduoduo.com",
      },
      {
        id: 4,
        image: global.logo + "w4.png",
        link: "alibaba.com",
      },
      {
        id: 5,
        image: global.logo + "w5.png",
        link: "tmall.com",
      },
      {
        id: 6,
        image: global.logo + "w6.png",
        link: "aliexpress.com",
      },
    ],
  },

  services: {
    title: "All services",
    subtitle: "Trusted For Our Services",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    services: [
      {
        icon: global.icons + "pic1.png",
        serial: "01",
        title: "Air Freight",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        icon: global.icons + "pic2.png",
        serial: "02",
        title: "Road Freight",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        icon: global.icons + "pic3.png",
        serial: "03",
        title: "Ocean Freight",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        icon: global.icons + "pic4.png",
        serial: "04",
        title: "Rail Freight",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        icon: global.icons + "pic5.png",
        serial: "05",
        title: "Warehousing",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
      {
        icon: global.icons + "pic6.png",
        serial: "06",
        title: "Project Cargo",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service.",
      },
    ],
  },

  achivements: [
    {
      count: "12",
      title: "Warehouses in U.S.A",
    },
    {
      count: "15",
      title: "Year of experience work with U.S.A",
    },
    {
      count: "60",
      title: "Kg Minimum lot valume",
    },
  ],

  estimation: {
    title: "Estimation",
    subtitle: "Has a wide range of solutions",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    solutions: [
      {
        serial: "01",
        icon: global.eicons + "pic1.png",
        title: "Solutions and specialized",
        description:
          "Our aim is to optimize and improve your supply chain so that we can give you the best service",
      },
      {
        serial: "02",
        icon: global.eicons + "pic2.png",
        title: "Multiple warehouses",
        description:
          "We provide multiple drop off and pickup locations so you don't have to worry. And you should not face any kind...",
      },
      {
        serial: "03",
        icon: global.eicons + "pic3.png",
        title: "Tracking made easy",
        description:
          "A tracking number for the entire process. so that you can find the exact position. this process will help you",
      },
    ],
  },

  clients: [
    global.logo + "w1.png",
    global.logo + "w2.png",
    global.logo + "w3.png",
    global.logo + "w4.png",
    global.logo + "w5.png",
    global.logo + "w1.png",
    global.logo + "w2.png",
    global.logo + "w3.png",
    global.logo + "w4.png",
    global.logo + "w5.png",
  ],

  testimonials: {
    title: "BIz haqimizda",
    subtitle: "Mijozlarning iliq fikrlari",
    feedbacks: [
      {
        image: global.feedbacks + "1.jpg",
      },
      {
        image: global.feedbacks + "2.jpg",
      },
      {
        image: global.feedbacks + "3.jpg",
      },
      {
        image: global.feedbacks + "4.jpg",
      },
      {
        image: global.feedbacks + "5.jpg",
      },
      {
        image: global.feedbacks + "6.jpg",
      },
      {
        image: global.feedbacks + "7.jpg",
      },
      {
        image: global.feedbacks + "8.jpg",
      },
      {
        image: global.feedbacks + "9.jpg",
      },
      {
        image: global.feedbacks + "10.jpg",
      },
      {
        image: global.feedbacks + "11.jpg",
      },
      {
        image: global.feedbacks + "12.jpg",
      },
    ],
    quotes: [
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Peter Len",
        designation: "Web Designer",
        image: global.testim + "pic1.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Giselle",
        designation: "Developer",
        image: global.testim + "pic2.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Josephine",
        designation: "Web Designer",
        image: global.testim + "pic3.jpg",
      },
      {
        quote:
          "Nemo enim ipsam voluptate voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratio ne voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi",
        name: "Penelope",
        designation: "Developer",
        image: global.testim + "pic4.jpg",
      },
    ],
  },

  plans: {
    title: "Our Plan",
    subtitle: "The right price for you whoever you are",
    plans: [
      {
        type: "Startup",
        price: "90$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
      {
        type: "Standard",
        price: "150$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
      {
        type: "Business",
        price: "260$",
        benifits: [
          "Additional",
          "Warehouing",
          "Custos Borkerage",
          "Unlimited Transfer",
        ],
      },
    ],
  },

  projects: {
    title: "Biz Haqimizda",
    subtitle: "Mijozlarimizning fikrlari",
    description: "",
    projects: [
      {
        image: global.feedbacks + "1.jpg",
      },
      {
        image: global.feedbacks + "2.jpg",
      },
      {
        image: global.feedbacks + "3.jpg",
      },
      {
        image: global.feedbacks + "4.jpg",
      },
      {
        image: global.feedbacks + "5.jpg",
      },
      {
        image: global.feedbacks + "6.jpg",
      },
      {
        image: global.feedbacks + "7.jpg",
      },
      {
        image: global.feedbacks + "8.jpg",
      },
      {
        image: global.feedbacks + "9.jpg",
      },
      {
        image: global.feedbacks + "10.jpg",
      },
      {
        image: global.feedbacks + "11.jpg",
      },
    ],
  },

  blogs: {
    title: "Our Blogs",
    subtitle: "Recent news & events",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry the standard dummy text ever since the when an printer took.",
    blogs: [
      {
        image: global.blog + "1.jpg",
        day: "02",
        month: "Aug",
        title:
          "At the end of the day, going forward, a new normal that has evolved from. your only logistic partner.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit. typesetting industry the standard dummy text ever since the when an printer.",
      },
      {
        image: global.blog + "2.jpg",
        day: "02",
        month: "Aug",
        title:
          "Going forward, a new normal that has evolved from generation. moving your products across all borders.",
        description:
          "New chip traps clusters of migrating tumor cells asperiortenetur, blanditiis odit. typesetting industry the standard dummy text ever since the when an printer.",
      },
    ],
  },
};
