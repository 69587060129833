import React from "react";

export default function SectionLogo({ _data }) {
  return (
    <>
      <div
        id="clients"
        className="section-full p-t90 p-b90 site-bg-white tw-client-logo2-area"
      >
        <div className="container home-client2-outer">
          <div className="section-content">
            <div className="owl-carousel home-client-carousel2 owl-btn-vertical-center owl-loaded owl-drag">
              <div className="owl-stage-outer">
                <div
                  className="owl-stage"
                  style={{
                    transform: "translate3d(-2877px, 0px, 0px)",
                    transition: "all 0.25s ease 0s",
                    width: "4221px",
                  }}
                >
                  {_data.logoLink.map((_object) => (
                    <div
                      key={_object.id}
                      className="owl-item"
                      style={{ width: "186.835px", marginRight: "5px" }}
                    >
                      <div className="item">
                        <div className="ow-client-logo">
                          <div className="client-logo client-logo-media">
                            <a
                              href={
                                _object.link.startsWith("http")
                                  ? _object.link
                                  : `http://${_object.link}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img src={_object.image} alt="" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="owl-nav disabled">
                <button type="button" role="presentation" className="owl-prev">
                  <i className="fa fa-angle-left"></i>
                </button>
                <button type="button" role="presentation" className="owl-next">
                  <i className="fa fa-angle-right"></i>
                </button>
              </div>
              <div className="owl-dots disabled"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
